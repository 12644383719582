import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserQuery } from '../user/user.query';
import {GenesystokenService} from '../genesystoken.service';
@Injectable({ providedIn: 'root' })
export class requestsService {
    baseApi = environment.baseApi;
    resion="eu_west_2";
    httpheaders = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'region':this.resion
            }
        )
    };

    fileHeaders = {
        headers: new HttpHeaders({
        })
    }

    imgHeaders = {
        headers: new HttpHeaders({
            'accept': 'image/*'
        })
    }

    audiohttpOptions = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/octet-stream',
                'accept': 'application/octet-stream',
                'region':this.resion
            }
        )
    };

    constructor(
        private userQuery: UserQuery,
        private http: HttpClient,private genesys:GenesystokenService) {

    }

    user() {

    }

    query(url, httpheaders = this.httpheaders) {
        return this.http.get<any>(
            `${this.baseApi}${url}`,
            httpheaders
        );
    }

    post(url, params = null, httpheaders = this.httpheaders) {
        return this.http.post<any>(
            `${this.baseApi}${url}`,
            params,
            httpheaders
        );
    }

    put(url, params = null) {
        return this.http.put<any>(
            `${this.baseApi}${url}`,
            params,
            this.fileHeaders
        );
    }

    delete(url, headers = null) {
        const httpheaders = this.httpheaders;
        return this.http.delete<any>(
            `${this.baseApi}${url}`,
            httpheaders
        );
    }

    // userPlanDetails() {
    //     return this.query('/getCognitoUserGroup');
    // }

    getChannels() {
        return this.query('/channel/list');
    }

    allServiceList() {
        return this.query('/service/list');
    }

    getService(id) {
        return this.query('/service/get?serviceId=' + id);
    }
    getUserAdminList(){
        //return this.query('/adminUser/findAll');
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/adminUser/findAll',{headers:headers_object})
    }
    OnechangegetUserAdminList(){
        //return this.query('/adminUser/findAll');
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/adminUser/findAll',{headers:headers_object})
    }
    createEditAdminUser(data){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/adminUser/saveOrUpdate',data, {headers:headers_object})
       // return this.post('/adminUser/saveOrUpdate' , data)
    }
    getUserAdmin(id){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/adminUser/findById?id='+ id, {headers:headers_object})
    }
    OnechangegetEnvirnmtList(){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/adminUser/organizations', {headers:headers_object})
    }
    getEnvirnmtList(){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/adminUser/organizations', {headers:headers_object})
    }
    //added on 14th March 2024
    getURLList(){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/adminUser/getAllRegions', {headers:headers_object})
     
    }
    //   getURLList(){
    //     return this.query('/adminUser/getAllRegions');
    // }
    getPacakgeListFiltred(params): Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    return this.http.post('https://onecgapi.cloudcx.ai/audit/getAuditDataFromDb',params, {headers:headers_object});
    }
   
  getPromotedPackageReport(params): Observable<any>{
    var userToken = this.genesys.token;
     var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
return this.http.post('https://onecgapi.cloudcx.ai/package/getPromotePackageReport',params, {headers:headers_object});
}
//added on 16/11/2023
AWSMembershipDetails(){
    var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    return this.http.get('https://onecgapi.cloudcx.ai/getMembershipDetails',{headers:headers_object})
}
//added on 7thjune2023
getPromoteRollBack(SprintId):Observable<any>{
    var userToken = this.genesys.token;
     var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
      return this.http.get('https://onecgapi.cloudcx.ai/package/rollbackPackagePromote?id='+SprintId, {headers:headers_object});
  }
    packageList():Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/package/findAll ', {headers:headers_object});
        
    }
    //commented on 12/10/23
        // promotePackage(envId ,packageId ){
        //         var userToken = this.genesys.token;
        //         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        //     return this.http.post('https://onecgapi.cloudcx.ai/package/promote?packageId='+packageId+'&envId='+envId,{}, {headers:headers_object});
        //     }
        promotePackage(toOrganizationId,packageId){
            var userToken = this.genesys.token;
             var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.post('https://onecgapi.cloudcx.ai/package/promote?toOrganizationId='+toOrganizationId+'&packageId='+packageId,{}, {headers:headers_object});
                }
            AddGitDetails(data):Observable<any>{
                var userToken = this.genesys.token;
                 var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.post('https://onecgapi.cloudcx.ai/gitDetails/saveOrUpdate',data, {headers:headers_object});
            }
            delGit(id){
                var userToken = this.genesys.token;
                 var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.delete('https://onecgapi.cloudcx.ai/gitDetails/delete?id='+id, {headers:headers_object});
            }
            getGitDetail(id){
                var userToken = this.genesys.token;
                 var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.get('https://onecgapi.cloudcx.ai/gitDetails/findById?id='+id, {headers:headers_object});
            }
            AddbackupSchedule(data):Observable<any>{
                var userToken = this.genesys.token;
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.post('https://onecgapi.cloudcx.ai/userProfile/addUpdateBackupSchedule',data, {headers:headers_object});
            }
            getBackupSchedule():Observable<any>{
                var userToken = this.genesys.token;
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.get('https://onecgapi.cloudcx.ai/userProfile/listBackupSchedules ', {headers:headers_object});
            }
            getBackupSchedulebyId(id){
                var userToken = this.genesys.token;
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.get('https://onecgapi.cloudcx.ai/userProfile/getBackupScheduleDetail?id='+id, {headers:headers_object});
            }
            deleteschedule(id){
                var userToken = this.genesys.token;
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.delete('https://onecgapi.cloudcx.ai/userProfile/deleteBackupSchedule?id='+ id, {headers:headers_object})
        
            }
            getRepositoryTypeList():Observable<any>{
                var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.get('https://onecgapi.cloudcx.ai/gitDetails/findAll ', {headers:headers_object});
            }
    getMember():Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/git/getMemberProjects', {headers:headers_object});   
    }
    getBrancheList(projectId):Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/git/getBranchListByProjectId?id='+projectId, {headers:headers_object});
        
    }
    importPackageToRepo(data):Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/git/importToGitlab',data, {headers:headers_object});
        }
    exportPackageToRepo(data):Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.post('https://onecgapi.cloudcx.ai/git/exportToGitlab',data, {headers:headers_object});
            }
    exportPackage(id): Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/package/getPackageChangesByPackage?id='+id, {headers:headers_object});
    }
    //added on 11/12/23
    // getFlowName(environmentId,flowtype) {
    //     return this.query(`/getFlows?environmentId=${environmentId}&flowtype=${flowtype}`);
    // }
   
    getFlowName1(environmentId,flowtype): Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        console.log(headers_object)
        return this.http.get('https://onecgapi.cloudcx.ai/getFlows?environmentId='+environmentId+'&flowtype='+flowtype, {headers:headers_object});
    }
    //added on 13/12/23
    promoteflow(params): Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    return this.http.post('https://onecgapi.cloudcx.ai/exportYml',params, {headers:headers_object});
    }
    //added on 21/12/2023
    underpromoteFlow(pmId): Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/getFlowPromoteStatus?id='+pmId, {headers:headers_object});
    }
    importPackage(data): Observable<any>{

        var userToken = this.genesys.token;
//this below line commented on 10/04/24
        // var headers_object = new HttpHeaders().set("Authorization",  userToken)
       //this below line applied on 10/04/24
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set("tokenType",this.genesys.tokenType)

        .set('region',this.resion)

        .set('Content-Type', 'application/json')

        .set('accept', 'application/json');

        return this.http.post('https://onecgapi.cloudcx.ai/package/updatePackageChanges',data, {headers:headers_object});

    }
    getPackageDetailsByPackage(id):Observable<any>{
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/package/findById?id='+id, {headers:headers_object});
    }
    delPackage(id){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.delete('https://onecgapi.cloudcx.ai/package/delete?id='+id, {headers:headers_object});
    }
    savePackage(parms){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/package/save', parms, {headers:headers_object});
    }
    deleteUserAdmin(id){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.delete('https://onecgapi.cloudcx.ai/adminUser/delete?id='+ id, {headers:headers_object})

    }

    createService(params) {
        return this.post('/service/addUpdate', params);
    }

    updateService(params) {
        return this.post('/service/addUpdate', params);
    }

    removeService(id) {
        return this.delete('/service/delete?serviceId=' + id);
    }

    allScriptsList() {
        return this.query('/script/list');
    }

    allScriptsByChannelId(id) {
        return this.query('/script/listScriptByChannel?channelId=' +id);
    }

    allServiceBychannelId(id) {
        return this.query('/service/listServiceByChannel?channelId='+id);
    }

    getScript(id) {
        return this.query('/script/get?scriptId=' + id);
    }
    createScript(params) {
        return this.post('/script/addUpdate', params);
    }

    updateScript(params) {
        return this.post('/script/addUpdate', params);
    }

    removeScript(id) {
        return this.delete('/script/delete?scriptId=' + id);
    }

    pricingPlans() {
        return this.query('/membership/list');
    }

    allSprints() {
        return this.query('/sprint/listSprint');
    }

    allSprintsByType(type) {
        return this.query('/sprint/listSprintByTestType?testType=' + type);
    }

    createSprint(params) {
        return this.post('/sprint/addUpdateSprint', params);
    }

    sprintById(id) {
        return this.query('/sprint/getSprint?sprintId=' + id);
    }
    // http://demo.onecg.cc:8901/api/v2/onecgtest/sprint/getSprintName/%7Bs[rintid%7D
    getsprintById(id,params) {
        return this.post('/sprint/getAutoPilotHistByDate?sprintId=' + id,params);
    }

    runSpring(id) {
        return this.query('/sprint/runSprint?sprintId=' + id);
    }

    stopSprint(sprint, client) {
        return this.query(`/sprint/terminateSprint?sprintId=${sprint}&clientId=${client}`);
    }
    stoploadSprint(sprint, ) {
        return this.query(`/sprint/terminateLoadTest?loadstartsprintId=${sprint}`);
    }

    removeSpring(id) {
        return this.delete('/sprint/deleteSprint?sprintId=' + id);
    }

    sprintTestHistory(id) {
        return this.query('/autopilotMessage/listMessageData?customerId=' + id);
    }

    sprintStatus(id) {
        return this.query('/sprint/getSprintStatus?clientId=' + id);
    }

    agentProgressSummary(id, clientId) {
        return this.query('/sprint/refreshSprintClientResponse?sprintId=' + id + '&clientId=' + clientId);
    }

    sprintTestHistoryList(id,params) {
        return this.post('/sprint/getAutoPilotHist?sprintId=' + id,params);
    }

    sprintAudioURL(id) {
        return this.baseApi + '/autopilotMessage/getAudio?customerId=' + id;
    }

    sprintAudioDownload(id) {
        return this.query('/autopilotMessage/getAudio?customerId=' + id, this.audiohttpOptions);
    }

    createStripeCheckoutSession(plan) {
        return this.post('/paymentcheckout/savePaymentCard?membershipId=' + plan);
    }

    savePaymentMethodForCustomer(sessiondId) {
        return this.post('/paymentcheckout/savePaymentMethodForCustomer?id=' + sessiondId, { id: sessiondId })
    }

    upgradePlan(planId) {
        return this.post('/paymentcheckout/upgardeMembership?membershipId=' + planId, { membershipId: planId });
    }

    getPaymentMethod() {
        return this.post('/paymentcheckout/getPaymentMethodForCustomer');
    }

    cancelMembership() {
        return this.query('/paymentcheckout/cancelMembership');
    }

    membershipHistory() {
        return this.query('/paymentcheckout/membershipHistory');
    }

    // getTestTypeForUser() {
    //     return this.query('/sprint/getTestTypeForGroup');
    // }

    getSuccessCriteria() {
        return this.query('/sprint/getSuccessCriteria');
    }

    getSuccessCriteriaReturnValue(id) {
        return this.query('/sprint/getCriteriaValues?successCriteriaId=' + id);
    }

    invoiceReport(params): Observable<any[]> {
        return this.post('/report/getInvoiceDetails', params);
    }

    highLevelReport(params): Observable<any[]> {
        return this.post('/report/getHighLevelDetails', params);
    }
    loadSprintReport(params): Observable<any> {
        return this.post('/sprint/getHighLevelLoadSprintReport', params);
    }
    loadSprintDeatilsReport(loadSprintclientId) {
        return this.query('/sprint/getLoadSprintDetailReport?loadSprintclientId=' + loadSprintclientId);
    }
    sprintReport(params): Observable<any> {
        return this.post('/report/billing', params);
    }

    uploadProfileImg(params) {
        return this.post('/user/uploadImage', params, this.fileHeaders);
    }

    // getProfileImg() {
    //     if(this.genesys.tokenType=='genesys'){
    //         var userToken = this.genesys.token;

    //         console.log(userToken)
    //           var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    //         return this.http.get('https://labapi.quicktest.ai/user/getImage',{headers:headers_object,responseType:'blob'})
    //     }else{
    //         const userToken = this.userQuery.getToken();
    //         console.log(userToken)
    //          var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    //         return this.http.get('https://labapi.quicktest.ai/user/getImage',{headers:headers_object,responseType:'blob'})
    //     }
       
    //    // return this.query('/user/getImage', {headers:headers_object});
    // }

    contactus(params): Observable<any> {

        return this.post('/user/contactUs', params);
    }
    getPaymentDetails() {
        return this.query('/paymentcheckout/getCardDetails');
    }
    getupdatePayment() {
        return this.query('/paymentcheckout/updatePayment');
    }
    SaveupdatePayment(paymentId) {
        return this.post('/paymentcheckout/savePaymentMethodForCustomer?id='+paymentId,{id:paymentId});
    }
    LoadsprintStatus(id) {
        return this.query('/sprint/getLoadTestStatus?loadstartsprintId=' + id);
    }
    LoadrunSpring(id) {
        return this.query('/sprint/runLoadSprint?sprintId=' + id);
    }

   LoadsprintTestHistoryList(id) {
        return this.query('/sprint/getLoadSprintData?sprintId=' + id);
    }
    LoadsprintTestHistoryListbyDate(id,params) {
        return this.post('/sprint/getLoadSprintDataByDate?sprintId=' + id,params);
    }

    loadStatussprintById(id) {
        return this.query('/sprint/getLoadTestStatus?loadstartsprintId=' + id);
    }

    loadStatusssprintDetails(id) {
        return this.query('/sprint/getLoadSprintDetailReport?loadSprintclientId='+id);
    }
    loadStatusssprintMessage(id) {
        return this.query('/autopilotMessage/listMessageData?customerId='+id);
    }

    getDashboardSprintData() {
        return this.query('/dashboardSprint/dashboardScheduleSprint');
    }

    getCountryPhoneNo(coundtryCode) {
        return this.query('/icd/listNumbers?countryCode='+coundtryCode);
    }

    addPhoneNumber(params) {
        return this.post('/fromDetails/addFromNumber', params);
    }

    getListNumber() {
        return this.query('/fromDetails/listNumbers');
    }
    getActiveNumber() {
        return this.query('/whatsappSettings/getActiveWhatsAppDetails');
    }

    deletePhonenumber(phoneNumber) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }),
            body: {
                number:phoneNumber
            },
          };

        var url='/fromDetails/releaseNumber';
        return this.http.delete<any>(
            `${this.baseApi}${url}`,options
        );
        // return this.ht
        // return this.delete('/fromDetails/releaseNumber?number=' + phoneNumber);
    }
    getChatSubChannels(channelId) {
        return this.query('/channel/subchannels?channelId=' +channelId);
    }

    cloudchatRefreshSprintExecution(id, clientId) {
        return this.query('/sprint/refreshSprintExecution?sprintId=' + id + '&clientId=' + clientId);
    }
    addWhatsappNumber(params): Observable<any> {
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://labapi.quicktest.ai/whatsappSettings/addWhatsappNumber', params,{headers:headers_object});
    }
    getWhatsAppDetails() {
        return this.query('/whatsappSettings/getWhatsAppDetails');
    }
    
    terminateWhatsappAddNumber(id) {
        return this.query('/whatsappSettings/terminateWhatsappAddNumber?id='+id);
    }
    getQRCode(id) {
        const userToken = this.userQuery.getToken();
        // console.log(userToken)
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);

        return this.http.get('https://labapi.quicktest.ai/whatsappSettings/getqrCode?id='+id, {headers:headers_object,responseType:'blob'});
    }
    WhatsApptStatus(id) {
        return this.query('/whatsappSettings/getqrstatus?id=' + id);
    }
    listofservice(){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/audit/serviceList',{headers:headers_object})
    }
    //added below 4 apis for user management
    getRoleListByUsername(){
        var userToken = this.genesys.token;
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/userProfile/getRoleListByUsername',{headers:headers_object})
    }
    getUserRoleList(){
        var userToken = this.genesys.token;
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/userProfile/getUserRoleList',{headers:headers_object})
}
getPendingInvitation(){
    var userToken = this.genesys.token;
    var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/userProfile/getPendingInvitesByOrganization',{headers:headers_object})
}
sendMemberInvite(params): Observable<any> {
    var userToken = this.genesys.token;
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    return this.http.post('https://onecgapi.cloudcx.ai/userProfile/sendMemberInvite', params,{headers:headers_object});
}
    //this api for entity's component list with gcloudapi baseurl
    serviceName(serviceid){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/audit/entityList?serviceName='+serviceid,{headers:headers_object})
    }
    getAction(PeoplePermissions,OAuthClient){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/audit/actionsList?serviceName='+PeoplePermissions+'&entityType='+ OAuthClient,{headers:headers_object})
    }
    getAuditData(params): Observable<any> {
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/audit/getAuditData', params,{headers:headers_object});
    }
   
    //neww added on 18/03
    Onetracklitelistofservice(orgId){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/audit/serviceList?orgId='+orgId,{headers:headers_object})
    }
 //new added on 18/03
    OnetrackliteserviceName(orgId,serviceid){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/audit/entityList?orgId='+orgId+'&serviceName='+serviceid,{headers:headers_object})
    }
 
 //new added on 18/03
    OnetracklitegetActiongetAction(orgId,PeoplePermissions,OAuthClient){
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/audit/actionsList?orgId='+orgId+'&serviceName='+PeoplePermissions+'&entityType='+ OAuthClient,{headers:headers_object})
    }

        getUserdetails() {
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/saveUpdateUser',{headers:headers_object});
    }
    //below code added on 02/-8/24
    OneDesignFetchSourceDate(params) {
        var userToken = this.genesys.token;
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/copyenvironment/retrieveFromGenesys',params,{headers:headers_object});
        }
        CopyEnvSavetoDest(params) {
            var userToken = this.genesys.token;
            var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.post('https://onecgapi.cloudcx.ai/copyenvironment/saveToDestination',params,{headers:headers_object});
            }
            getcopyEnvStatus(uniqueId){
                var userToken = this.genesys.token;
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                    return this.http.get('https://onecgapi.cloudcx.ai/copyenvironment/getCopyStatus?copyDetailsId='+uniqueId,{headers:headers_object})
            }
            getCopyEnvFailedCount(params) {
                var userToken = this.genesys.token;
                var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
                return this.http.post('https://onecgapi.cloudcx.ai/copyenvironment/getCopyResults',params,{headers:headers_object});
            }
    rollbackAction(params) {
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/rollback',params,{headers:headers_object});
    }
    saveRoleListForUser(params) {
        var userToken = this.genesys.token;
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.post('https://onecgapi.cloudcx.ai/userProfile/saveRoleListForUser',params,{headers:headers_object});
    }
    getRoleListForUser(userId){
        var userToken = this.genesys.token;
        var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
            return this.http.get('https://onecgapi.cloudcx.ai/userProfile/getRoleListForUser?userId='+userId,{headers:headers_object})
    }
    
    AWSUserDetails() {
        var userToken = this.genesys.token;
         var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/getUserDetails',{headers:headers_object});
    }
//new userdetail added on 28th june to point onetrack
newUserDetails() {
    var userToken = this.genesys.token;
    // console.log(userToken)
     var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    return this.http.get('https://onecgapi.cloudcx.ai/getUserDetails',{headers:headers_object});
}
newlistofservice(orgId){
    var userToken = this.genesys.token;
     var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/audit/serviceList?orgId='+orgId,{headers:headers_object})
}
//this api for entity's component list with onetrack baseurl
newserviceName(orgId,serviceid){
    var userToken = this.genesys.token;
     var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/audit/entityList?orgId='+orgId+'&serviceName='+serviceid,{headers:headers_object})
}
newgetAction(orgId,PeoplePermissions,OAuthClient){
    var userToken = this.genesys.token;
     var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
        return this.http.get('https://onecgapi.cloudcx.ai/audit/actionsList?orgId='+orgId+'&serviceName='+PeoplePermissions+'&entityType='+ OAuthClient,{headers:headers_object})
}
newrollbackAction(params) {
    var userToken = this.genesys.token;
     var headers_object = new HttpHeaders().set("Authorization",  userToken).set('region',this.resion);
    return this.http.post('https://onecgapi.cloudcx.ai/rollback',params,{headers:headers_object});
}
}