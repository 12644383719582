import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { UserQuery } from '../services/user/user.query';
import {requestsService} from '../services/requests/requests.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  username = null;
  password1="";
  verifyUser: FormGroup;
  orgId:any;
  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,private user1: UserQuery,private apiService: requestsService, private toaster: ToastrService,) {
    this.verifyUser = new FormGroup({
      code: new FormControl('', [Validators.required])
    });

    const user = this.activeRouter.snapshot.queryParams.user;
    const password=this.activeRouter.snapshot.queryParams.password;
    this.orgId=this.activeRouter.snapshot.queryParams.org;
    if (user) {
      this.username = user;
      this.password1=password;
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
  }

  verify(event) {
    console.log(event)
    event.preventDefault();
    console.log(this.verifyUser)
    if (this.verifyUser.invalid) {
    
      return;
    }
    const code = this.verifyUser.value.code;
    console.log(code)
    Auth.confirmSignUp(this.username, code)
      .then(res => {
       console.log(res)
          Auth.signIn(this.username, this.password1).then((res1: any) => {
console.log(res1)
            this.getcurrentCredential().then(token=>{
              var tempToken=token as string;
                this.user1.setToken(tempToken);
                this.user1.setUserName(this.username);
                this.user1.setPassword(this.password1);
                localStorage.setItem('UserCred', JSON.stringify({ Username: this.username, Password: this.password1 }));
                //this.router.navigateByUrl('/home');
                this.apiService.getUserdetails().subscribe(s=>{
                  this.router.navigateByUrl('/home');
                });
            })
           
          }, err => {
           console.log(err)
          });
      }, err => {
this.toaster.warning(err?.message)
return;
      });
  }

  async getcurrentCredential() : Promise<string>{
    const result = await Auth.currentSession();
    console.log(result)
    var IdToken=result.getIdToken().getJwtToken();
    return IdToken;
  }
}
